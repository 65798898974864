















import Vue from 'vue'
import dayjs from 'dayjs'
import { productStore } from '~/store'

export default class Footer extends Vue {
  get postsDate() {
    return productStore.postsDate
  }

  get postsCategory() {
    return productStore.categories
  }

  getDate(date: Date) {
    return dayjs(date).format('YYYY.MM')
  }
}
